import * as React from "react";
import { useNavigate } from "react-router-dom";
import styles from "./IflowHeader.module.css";

export default function IflowHeader() {
  const navigate = useNavigate();
  const navigateToHome = () => {
    navigate("");
  };
  return (
    <header className={styles.container}>
      <img className={styles.iflowLogo} src="/iflowecommerce.png" alt="iFLOW Logo" onClick={navigateToHome} />
    </header>
  );
}
