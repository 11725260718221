import * as React from "react";
import styles from "./MySavedTracking.module.css";
import { deleteTracking, getMyTrackingSaved } from "../../services/myTrackingService";
import { Card, Divider, IconButton, List, ListItem, ListItemButton, ListItemText } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { useNavigate } from "react-router-dom";

export default function MySavedTracking() {
  let navigate = useNavigate();

  const [trackings, setTrackings] = React.useState([]);
  React.useEffect(() => {
    setTrackings(getMyTrackingSaved());
  }, []);

  const goToTrackingPage = (orderId: string) => {
    navigate(`/tracking/${orderId}`);
  };

  const deleteMyTracking = (orderId: string) => {
    deleteTracking(orderId);
    setTrackings(getMyTrackingSaved());
  };

  if (trackings.length == 0) {
    return <></>;
  }
  return (
    <div className={styles.container}>
      <div className={styles.title}>Mis envíos guardados</div>
      <div className={styles.cardsContainer}>
        <List>
          {trackings.map((tracking: any) => {
            return (
              <ListItem
                style={{ backgroundColor: "#f8f8f8", marginBottom: 5 }}
                disablePadding
                key={tracking.tracking}
                secondaryAction={
                  <IconButton edge="end" aria-label="delete" onClick={() => deleteMyTracking(tracking.tracking)}>
                    <DeleteIcon />
                  </IconButton>
                }
              >
                <ListItemButton onClick={() => goToTrackingPage(tracking.tracking)}>
                  <ListItemText primaryTypographyProps={{ color: "#f16523", fontWeight: 500 }} primary={tracking.name ? tracking.name : tracking.tracking} secondary={tracking.name ? tracking.tracking : null} />
                </ListItemButton>
              </ListItem>
            );
          })}
        </List>
      </div>
    </div>
  );
}
