interface MyTracking {
  tracking: string;
  name: string;
  timestamp: number;
}

export const getMyTrackingSaved = () => {
  const saved = JSON.parse(localStorage.getItem("trackingSaved") || "[]");
  return saved.sort((a: MyTracking, b: MyTracking) => b.timestamp - a.timestamp);
};

export const saveAllTracking = (myTrackings: Array<MyTracking>) => {
  localStorage.setItem("trackingSaved", JSON.stringify(myTrackings));
};

export const saveNewTracking = (tracking: string, name: string) => {
  const saved: Array<MyTracking> = getMyTrackingSaved();
  const indexExist = saved.findIndex((t) => t.tracking == tracking);

  if (indexExist > -1) {
    if (name) {
      saved[indexExist].name = name;
    }
    saved[indexExist].timestamp = Date.now();
  } else {
    saved.push({
      tracking,
      name,
      timestamp: Date.now(),
    });
  }
  saveAllTracking(saved);
};

export const deleteTracking = (trackingId: string) => {
  const saved: Array<MyTracking> = getMyTrackingSaved();
  const newSave = saved.filter((t) => t.tracking != trackingId);
  saveAllTracking(newSave);
};
