import * as React from "react";
import { useRoutes, Navigate } from "react-router-dom";
import SearchPage from "../pages/SearchPage";
import TrackingPage from "../pages/TrackingPage";
import { NotFound } from "../pages/NotFound";

const PublicRoutes = () => {
  return useRoutes([
    { path: "/", element: <SearchPage /> },
    { path: "/tracking/:orderId", element: <TrackingPage /> },
    { path: "*", element: <NotFound /> },
  ]);
};

export default PublicRoutes;
