import * as React from "react";
import styles from "./NpsRate.module.css";
import {
  Button,
  CircularProgress,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import { sendNps } from "../../services/httpServices";

interface Props {
  trackingId: string;
  npsSaved: boolean;
}

export default function NpsRate(props: Props) {
  const { trackingId, npsSaved } = props;
  const [score, setScore] = React.useState(-1);
  const [description, setDescription] = React.useState("");
  const [sendingResponse, setSendingResponse] = React.useState(false);
  const [responseSent, setResponseSent] = React.useState(npsSaved);

  const handleChangeText = (event: any) => {
    setDescription(event.target.value);
  };

  const sendResponse = () => {
    setSendingResponse(true);

    sendNps(trackingId, score, description).finally(() => {
      setSendingResponse(false);
      setResponseSent(true);
    });
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setScore(parseInt(event.target.value));
  };

  const textFieldLabel =
    score >= 7
      ? "¿Cuál es el motivo de tu calificación?"
      : "Lamentamos la experiencia que tuviste, ¿Cuál es el motivo de tu calificación?";
  const buttonDisabled = score < 0 || !description;
  return (
    <div className={styles.container}>
      {!responseSent && !sendingResponse && (
        <>
          <div className={styles.title}>
            En base a tu experiencia con la entrega de tu pedido ¿Qué tan
            probable es que recomiendes nuestro servicio?
          </div>
          <div className={styles.subtitle}>
            Siendo 0 "Nada probable" y 10 "Muy probable"
          </div>
          <RadioGroup row style={{ margin: "0 auto", maxWidth: 430 }}>
            {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((n: number) => {
              return (
                <FormControlLabel
                  key={n}
                  style={{ marginLeft: -3, marginRight: -3 }}
                  value={n}
                  control={
                    <Radio
                      checked={score === n}
                      onChange={handleChange}
                      value={n}
                      name="radio-buttons"
                      inputProps={{ "aria-label": "A" }}
                    />
                  }
                  label={n}
                  labelPlacement="bottom"
                />
              );
            })}
          </RadioGroup>

          {score >= 0 && (
            <div>
              <TextField
                className={styles.multiline}
                placeholder={textFieldLabel}
                multiline
                maxRows={6}
                variant="standard"
                inputProps={{ maxLength: 100 }}
                onChange={handleChangeText}
              />
              <div className={styles.comments}>
                Tus comentarios nos ayudan a mejorar el servicio.
              </div>
              <div className={styles.buttonContainer}>
                <Button
                  variant="text"
                  onClick={sendResponse}
                  disabled={buttonDisabled}
                >
                  Enviar
                </Button>
              </div>
            </div>
          )}
        </>
      )}
      {sendingResponse && (
        <div className={styles.sendingLoaderContainer}>
          <CircularProgress />
        </div>
      )}
      {responseSent && (
        <div className={styles.responseSentContainer}>
          <div>
            <h2 className={styles.bigGreeting}>
              ¡Gracias por tus comentarios!
            </h2>
            <div className={styles.greetingDetail}>
              Con esta breve encuesta nos ayudas a seguir mejorando nuestro
              servicio de envíos.
              <br /> ¡Te agradecemos tu tiempo!
            </div>
          </div>
          <div className={styles.thanksLogoContainer}>
            <img
              className={styles.thanksLogo}
              src="/Appreciation-bro.svg"
              alt="Thanks"
            />
          </div>
        </div>
      )}
    </div>
  );
}
