import * as React from "react";
import styles from "./ShippingStep.module.css";

interface Props {
  step: Step;
  stepIndex: number;
  currentStep: number;
}

export default function ShippingStep({ stepIndex, step, currentStep }: Props) {
  const isCurrentStep = stepIndex == currentStep;
  return (
    <div className={styles.container}>
      <div
        className={styles.title}
        style={{ color: isCurrentStep ? "#424242" : "#AAAAAA" }}
      >
        {step.title}
      </div>
      {step.states.map((state, index: number) => {
        const isCurrentState = isCurrentStep && index == step.states.length - 1;
        return (
          <div
            className={styles.stateContainer}
            key={index}
            style={{ color: isCurrentState ? "black" : "" }}
          >
            {state.date} | {state.details}
          </div>
        );
      })}
    </div>
  );
}
