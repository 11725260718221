import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import * as React from "react";

export default function TermsAndConditions({ open, handleClose }: any) {
  const descriptionElementRef = React.useRef<HTMLElement>(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  return (
    <Dialog open={open} onClose={handleClose} scroll="paper" aria-labelledby="scroll-dialog-title" aria-describedby="scroll-dialog-description">
      <DialogTitle id="scroll-dialog-title">Términos y condiciones</DialogTitle>
      <DialogContent dividers>
        <DialogContentText id="scroll-dialog-description" ref={descriptionElementRef} tabIndex={-1}>
          <p>El usuario que acceda a esta página web conoce y acepta los términos relativos a su utilización que a continuación se detallan:</p>
          <p>
            El material que integra la presente página es propiedad de IFlow SA, todos los derechos se encuentran reservados, la información y las imágenes que en ella se presentan no podrán ser reproducidas o usadas sin el consentimiento expreso de
            IFlow SA, bajo ninguna circunstancia.
          </p>
          <p>La información contenida en la presente página es exclusivamente para uso personal. El usuario se compromete a no vender, reproducir, modificar, alterar o comercializar total o parcialmente la presente página o su contenido.</p>
          <p>
            El usuario no podrá volcar términos o utilizar expresiones injuriosas, calumniantes o contrarias a las buenas costumbres. No podrá transmitir información o material que pueda, concreta o eventualmente, violar derechos de un tercero o que
            contenga virus o cualquier otro componente dañino.
          </p>
          <p>IFlow SA se reserva el derecho de extraer y editar en su totalidad o fraccionado cualquier mensaje o material suscripto o remitido por el usuario.</p>
          <p>El usuario garantiza a IFlow SA el permiso para la utilización de cualquier información, sugerencia, idea, dibujo o concepto vertidos, con el propósito que IFlow SA determine y sin ningún derecho de compensación en favor del usuario.</p>
          <p>
            IFlow SA no brinda garantía verbal o escrita sobre el material publicado en la presente página, incluyendo pero no limitando a que la información publicada se encuentre libre de errores o virus. La utilización de la información es de
            riesgo exclusivo para el usuario.
          </p>
          <p>
            En tal sentido, IFlow SA ha realizado todos los esfuerzos razonables para asegurar la exactitud de la información expuesta en esta página. IFlow SA se reserva el derecho de efectuar correcciones o completar la información en cualquier
            momento sin previo aviso al usuario. Bajo ninguna circunstancia, IFlow SA será responsable por los daños resultantes del empleo incorrecto de los materiales o información contenidos en esta página.
          </p>
          <p>El presente acuerdo rige desde el momento de acceso a la página de IFlow SA. El usuario puede dar por finalizado el acuerdo con su desconexión del site y la destrucción del material obtenido.</p>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cerrar</Button>
      </DialogActions>
    </Dialog>
  );
}
