import * as React from "react";
import styles from "./HelpSection.module.css";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: "white",
  minHeight: 30,
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    margin: "0px 0",
    marginLeft: theme.spacing(1),
  },
  paddingTop: "0px",
  "&:before": {
    display: "none",
    backgroundColor: "transparent",
    height: "0",
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  border: "none",
  padding: "0px 16px 16px 50px",
}));

interface Props {
  faqs: Array<FAQ>;
}
export default function HelpSection(props: Props) {
  const { faqs } = props;
  const [expanded, setExpanded] = React.useState<number>(-1);

  const handleChange =
    (index: number) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? index : -1);
    };

  return (
    <div className={styles.container} id="faq">
      {faqs.map((faq, index) => {
        return (
          <Accordion
            expanded={expanded === index}
            onChange={handleChange(index)}
            key={index}
          >
            <AccordionSummary>
              <Typography
                style={{
                  fontSize: "14px",
                  fontWeight: "600",
                  lineHeight: "1.5",
                  color: "#424242",
                }}
              >
                {faq.question}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <div
                  className={styles.richText}
                  dangerouslySetInnerHTML={{ __html: faq.answer }}
                />
              </Typography>
            </AccordionDetails>
          </Accordion>
        );
      })}
    </div>
  );
}
