import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export const NotFound = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const hash = window.location.hash;
    const newRoute = hash.replace("#", "");
    navigate(newRoute);
  }, [navigate]);

  return null;
};
