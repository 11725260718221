import * as React from "react";
import { useParams } from "react-router-dom";
import BasicInformation from "../components/BasicInformation/BasicInformation";
import HelpSection from "../components/HelpSection/HelpSection";
import GoogleMapsSection from "../components/GoogleMapsSection/GoogleMapsSection";
import NpsRate from "../components/NpsRate/NpsRate";
import Newsletter from "../components/Newsletter/Newsletter";
import { getInfoByTrackingId } from "../services/httpServices";
import Card from "../components/Card/Card";
import ShipmentCards from "../components/ShipmentCards/ShipmentCards";

import styles from "./TrackingPage.module.css";
import { ContactSupport, RateReview, Feed } from "@mui/icons-material";
import HelpIcon from "@mui/icons-material/Help";
import WelcomeCard from "../components/WelcomeCard/WelcomeCard";
import { showNps } from "../services/trackingDetailService";

export default function TrackingPage() {
  const params = useParams();
  const [loadingOrder, setLoadingOrder] = React.useState(true);
  const [faqIsNotVisible, setFaqIsNotVisible] = React.useState(true);
  const [order, setOrder] = React.useState<any>({});
  const deliveryModeLabels: any = {
    Normal: "envío",
    Inversa: "retiro",
  };
  const deliveryModeLabel = deliveryModeLabels[order.delivery_mode || "Normal"];

  React.useEffect(() => {
    getInfoByTrackingId(params.orderId || "")
      .then((response) => {
        if (response?.tracking_id) {
          setOrder(response);
        } else {
          console.log("La etiqute no existe");
        }
      })
      .catch((err) => {
        console.log("Se produjo un error", err);
      })
      .finally(() => {
        setLoadingOrder(false);
      });

    function isElementInViewport(el: any) {
      const rect = el.getBoundingClientRect();
      return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <=
          (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <=
          (window.innerWidth || document.documentElement.clientWidth)
      );
    }

    const checkElementVisibility = () => {
      const element = document.getElementById("faq");
      if (element && isElementInViewport(element)) {
        setFaqIsNotVisible(false);
      } else {
        setFaqIsNotVisible(true);
      }
    };

    // Chequea la visibilidad inicial del elemento
    checkElementVisibility();

    // Agrega el event listener para el evento de scroll
    window.addEventListener("scroll", checkElementVisibility);

    // Limpia el event listener cuando el componente se desmonta
    return () => {
      window.removeEventListener("scroll", checkElementVisibility);
    };
  }, []);

  const showFaq =
    loadingOrder || (!loadingOrder && order.faq && order.faq.length > 0);
  const showNpsSection = showNps(order);

  return (
    <div className={styles.container}>
      <div className={styles.centered}>
        <h1 className={styles.title}>Número de seguimiento {params.orderId}</h1>
        <div className={styles.cardsContainer}>
          <div className={styles.column}>
            <Card loading={loadingOrder}>
              <WelcomeCard deliveryMode={order.delivery_mode} order={order} />
            </Card>
            {showNpsSection && (
              <Card loading={loadingOrder}>
                <NpsRate
                  trackingId={order.tracking_id}
                  npsSaved={order.nps_saved}
                />
              </Card>
            )}
            <ShipmentCards order={order} loadingOrder={loadingOrder} />
          </div>
          <div className={styles.column}>
            <Card
              loading={loadingOrder}
              title={
                order.delivery_mode == "Inversa"
                  ? "Detalles de tu retiro"
                  : "Detalles de tu pedido"
              }
            >
              <BasicInformation order={order} />
            </Card>
            {showFaq && (
              <Card loading={loadingOrder} title={`Necesito ayuda`}>
                <HelpSection faqs={order.faq} />
              </Card>
            )}
            {/* <GoogleMapsSection></GoogleMapsSection> */}

            {/* <Newsletter></Newsletter> */}
          </div>
        </div>
      </div>
      {faqIsNotVisible && showFaq && (
        <div className={styles.helpButton}>
          <a href="#faq">
            <HelpIcon
              fontSize="large"
              style={{ fontSize: 70 }}
              className={styles.helpIcon}
            />
          </a>
        </div>
      )}
    </div>
  );
}
