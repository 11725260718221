import * as React from "react";
import FileDownload from "@mui/icons-material/FileDownload";
import styles from "./DownloadLabelButton.module.css";
import { Button, colors } from "@mui/material";

export default function DownloadLabelButton({  printPngUrl, printUrl }: any) {

  if(!printPngUrl && !printUrl) {
    return <></>
  }

  const print = printUrl || printPngUrl


  const buttonStyles = {
      borderColor: '#8dc63f',
      color: '#8dc63f',
      '&:hover': {
        borderColor: '#8dc63f',
        color: '#8dc63f',
      },
  };

  return (
    <div className={styles.container}>
      <a href={print} target="_blank" >
        <Button sx={buttonStyles} variant="outlined" endIcon={<FileDownload />}>
          Imprimir etiqueta
        </Button>
      </a>
    </div>
  );
}
