import * as React from "react";
import styles from "./IflowFooter.module.css";
import TermsAndConditions from "../TermsAndConditions/TermsAndConditions";

export default function IflowFooter() {
  const [openTerms, setOpenTerms] = React.useState(false);

  const handleCloseTerms = () => {
    setOpenTerms(false);
  };

  const handleOpenTerms = () => {
    setOpenTerms(true);
  };

  return (
    <footer className={styles.footer}>
      <div className={styles.rightPanel}>
        <div className={styles.iflowTitle}>iFlow eCommerce</div>
        <div className={styles.content}>
          <div>
            <a href="https://maps.app.goo.gl/uzL1szWhfhPocp35A" target="_blank">
              LAVOISIER 494 (B1616AWJ) - PABLO NOGUÉS - BUENOS AIRES - ARGENTINA
            </a>{" "}
            |
          </div>
          <div>COPYRIGHT 2023 | IFLOW | ALL RIGHTS RESERVED | Prestador de Enacom R.N.P.S.P.: 1003</div>
        </div>
        <div className={styles.terms}>
          <span onClick={handleOpenTerms}>Términos y condiciones</span> |{" "}
          <a href="https://www.argentina.gob.ar/economia/comercio/defensadelconsumidor" target="_blank">
            Defensa al consumidor
          </a>
        </div>
      </div>
      <TermsAndConditions open={openTerms} handleClose={handleCloseTerms} />
    </footer>
  );
}
