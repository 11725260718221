export const showNps = (order: any) => {
  const shippingsCondition = order?.shippings?.filter((shipping: any) => {
    const actualStep = shipping?.steps?.findLast((step: Step) => {
      return step?.states?.length > 0
    })
    if(actualStep) {
      return actualStep?.showNps == true
    }
    return false


  });
  if(!shippingsCondition) {
    return false
  }
  return shippingsCondition?.length === order?.shippings?.length
};
