import * as React from "react";
import styles from "./Search.module.css";
import {
  Alert,
  Checkbox,
  FormControlLabel,
  Snackbar,
  TextField,
} from "@mui/material";
import { orderSearch } from "../../services/httpServices";
import { LoadingButton } from "@mui/lab";
import { useNavigate } from "react-router-dom";
import MySavedTracking from "../MySavedTracking/MySavedTracking";
import { saveNewTracking } from "../../services/myTrackingService";

export default function Search() {
  let navigate = useNavigate();

  const [order, setOrder] = React.useState("");
  const [showErrorMessage, setShowErrorMessage] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [initialLoading, setInitialLoading] = React.useState(true);
  const [saveOrderLocalStorage, setSaveOrderLocalStorage] =
    React.useState(true);
  const [packageName, setPackageName] = React.useState("");

  React.useEffect(() => {
    const hash = window.location.hash;
    if (hash.substring(0, 16) === "#/tracking/code/") {
      setLoading(true);
      const order = hash.substring(16, hash.length);
      orderSearch(order)
        .then((response) => {
          const orderResponse = response?.tracking_id;
          if (orderResponse) {
            navigate(`/tracking/${orderResponse}`);
          }
        })
        .finally(() => {
          setInitialLoading(false);
        });
    } else if (hash.substring(0, 7) === "#/close") {
      window.location.href = `https://track.iflow21.com/${hash}`;
    } else {
      setInitialLoading(false);
    }
  }, []);

  const handleChangeOrder = (e: any) => {
    setShowErrorMessage(false);
    setOrder((e.target.value || "").trim().toUpperCase());
  };

  const getOrderNumber = async () => {
    const response = await orderSearch(order);
    return response?.tracking_id;
  };

  const handleClick = async () => {
    try {
      setErrorMessage("");
      if (order) {
        setLoading(true);
        const orderResponse = await getOrderNumber();
        setLoading(false);
        if (orderResponse) {
          if (saveOrderLocalStorage) {
            saveNewTracking(orderResponse, packageName);
          }
          navigate(`/tracking/${orderResponse}`);
        } else {
          setErrorMessage(
            "No pudimos encontrar tu número de seguimiento. Revisa que esté bien escrito o solicitalo nuevamente al vendedor."
          );
          setShowErrorMessage(true);
        }
      }
    } catch (error) {
      setLoading(false);
      setErrorMessage("El pedido aun no puede ser trackeado");
      setShowErrorMessage(true);
    }
  };

  const handleClose = () => {
    setShowErrorMessage(false);
  };

  const handleChangeSaveOrder = (event: any) => {
    setSaveOrderLocalStorage(event.target.checked);
  };

  const handleChangePackageName = (event: any) => {
    setPackageName(event.target.value);
  };

  if (initialLoading) {
    return null;
  } else {
    return (
      <>
        <Snackbar
          open={showErrorMessage}
          autoHideDuration={6000}
          onClose={handleClose}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
            {errorMessage}
          </Alert>
        </Snackbar>

        <div className={styles.container}>
          <div className={styles.title}>Seguí tu envío</div>
          <TextField
            label="Número de seguimiento"
            disabled={loading}
            placeholder="Ej. OR00001234"
            variant="filled"
            style={{ width: 400 }}
            onChange={handleChangeOrder}
            value={order}
          />
          <div className={styles.helpTextField}>
            Tu número de seguimiento se encuentra en el mail que te enviamos al
            confirmarse tu envío.
          </div>
          <div className={styles.buttonContainer}>
            <LoadingButton
              onClick={handleClick}
              loading={loading}
              variant="contained"
              style={{ maxWidth: 400, width: "100%", height: 50 }}
            >
              Ver seguimiento
            </LoadingButton>
          </div>
          {order && (
            <div className={styles.addTrackingContainer}>
              <FormControlLabel
                className={styles.textCheckbox}
                control={
                  <Checkbox
                    onChange={handleChangeSaveOrder}
                    checked={saveOrderLocalStorage}
                    size="small"
                  />
                }
                label="Guardar en MIS ENVIOS en este dispositivo"
              />
              {saveOrderLocalStorage && (
                <TextField
                  className={styles.saveTextField}
                  label="Descripción del paquete"
                  size="small"
                  value={packageName}
                  onChange={handleChangePackageName}
                />
              )}
            </div>
          )}
        </div>
        <MySavedTracking />
      </>
    );
  }
}
