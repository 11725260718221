import * as React from "react";

import styles from "./Card.module.css";
import { CircularProgress, Card as MuiCard } from "@mui/material";

export default function Card({ loading, children, title, icon }: any) {
  const minHeight = loading ? { minHeight: 200 } : {};

  return (
    <MuiCard className={styles.containerCard} style={minHeight}>
      {loading && (
        <div className={styles.spinnerContainer}>
          <CircularProgress />
        </div>
      )}
      {!loading && (
        <>
          {title && (
            <div className={styles.title}>
              {icon && <div className={styles.iconContainer}>{icon}</div>}
              {title}
            </div>
          )}
          <div className={styles.content}>{children}</div>
        </>
      )}
    </MuiCard>
  );
}
