import * as React from "react";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import styles from "./EstimatedDate.module.css";

export default function EstimatedDate({ estimatedDate }: any) {
  if (!estimatedDate || !estimatedDate.min || !estimatedDate.max /*|| estimatedDate.hide_dates*/) {
    return <></>;
  }

  // const addCero = (n: any) => {
  //   return n > 9 ? n : "0" + n;
  // };
  // const formatDate = (date: Date) => {
  //   const month = addCero(date.getMonth() + 1);
  //   const day = addCero(date.getDate());
  //   return `${day}/${month}`;
  // };

  const minDate = estimatedDate.min;
  const maxDate = estimatedDate.max;
  return (
    <div className={styles.container}>
      <div className={styles.icon}>
        <InfoOutlinedIcon fontSize="small" style={{ color: "#8dc63f" }} />
      </div>
      <div className={styles.text}>Fecha estimada de entrega {minDate == maxDate ? minDate : `entre ${minDate} y ${maxDate}`}.</div>
    </div>
  );
}
