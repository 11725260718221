import * as React from "react";
import styles from "./ShipmentCards.module.css";
import Card from "../Card/Card";
import ShipmentCard from "../ShipmentCard/ShipmentCard";

export default function ShipmentCards({ order, loadingOrder }: { order: Order; loadingOrder: boolean }) {
  const shippingSize = order?.shippings?.length;
  return (
    <>
      {loadingOrder && <Card loading={loadingOrder}></Card>}
      {!loadingOrder && (
        <>
          {shippingSize > 1 && <div className={styles.divider} />}
          {shippingSize > 1 && <div className={styles.title}>Tu pedido esta compuesto por {shippingSize} paquetes, cada uno tiene un número de envío:</div>}
          {order?.shippings?.map((shipping, index: number) => {
            let title = `${shipping.shipment_id}`;
            if (order.shippings.length > 1) {
              title = `${title} (Envío ${index + 1}/${order.shippings.length})`;
            }
            return (
              <Card key={shipping.shipment_id} title={title}>
                <ShipmentCard shipping={shipping} index={index} shippingSize={order.shippings.length} estimatedDate={shipping.estimatedDeliveryDate} />
              </Card>
            );
          })}
        </>
      )}
    </>
  );
}
