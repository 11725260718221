import * as React from "react";

import { Box } from "@mui/material";
import Search from "../components/Search/Search";

export default function SearchPage() {
  return (
    <Box>
      <div>
        <Search />
      </div>
    </Box>
  );
}
