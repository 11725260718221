import * as React from "react";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import styles from "./BasicInformation.module.css";

export default function BasicInformation({ order }: { order: Order }) {
  const sender = order?.sender?.name;
  const ref = order?.order_id;
  // const receiver = order?.receiver?.name;
  return (
    <div className={styles.container}>
      <div>
        {sender && (
          <div className={styles.section}>
            <div className={styles.info}>{sender}</div>
            <div className={styles.title}>Vendedor</div>
          </div>
        )}
        {ref && (
          <div className={styles.section}>
            <div className={styles.info}>{ref}</div>
            <div className={styles.title}>Número de órden del vendedor</div>
          </div>
        )}
        {/* {receiver && (
          <div className={styles.section}>
            <div className={styles.info}>{receiver}</div>
            <div className={styles.title}>Comprador</div>
          </div>
        )} */}
      </div>
    </div>
  );
}
