import * as React from "react";
import styles from "./ShipmentCard.module.css";
import EstimatedDate from "../EstimatedDate/EstimatedDate";
import ShippingSteps from "../ShippingSteps/ShippingSteps";
import DownloadLabelButton from "../DownloadLabelButton/DownloadLabelButton";

interface Props {
  shipping: Shipping;
  index: number;
  shippingSize: number;
  estimatedDate: any;
}

export default function ShipmentCard({ shipping, estimatedDate }: Props) {
  return (
    <div className={styles.container}>
      <DownloadLabelButton printPngUrl={shipping.printPngUrl}  printUrl={shipping.printUrl}></DownloadLabelButton>
      <EstimatedDate estimatedDate={estimatedDate} />
      <ShippingSteps steps={shipping.steps} />
    </div>
  );
}
