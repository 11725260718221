import * as React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { BrowserRouter as Router } from "react-router-dom";
import PublicRoutes from "./routes/PublicRoutes";
import "./App.css";
import Layout from "./Layout";
import IflowHeader from "./components/IflowHeader/IflowHeader";
import IflowFooter from "./components/IflowFooter/IflowFooter";

const theme = createTheme({
  palette: {
    primary: {
      light: "#ff9651",
      main: "#f16522",
      dark: "#b73400",
      contrastText: "#fff",
    },
    secondary: {
      light: "#c8f457",
      main: "#94c11f",
      dark: "#619000",
      contrastText: "#000",
    },
  },
});

export default function App() {
  return (
    <>
      <Router>
        <IflowHeader />
        <main>
          <ThemeProvider theme={theme}>
            <PublicRoutes />
          </ThemeProvider>
        </main>
        <IflowFooter />
      </Router>
    </>
  );
}
