import * as React from "react";
import styles from "./ShippingSteps.module.css";
import ShippingStep from "../ShippingStep/ShippingStep";
import CheckIcon from "@mui/icons-material/Check";

interface Props {
  steps: Array<Step>;
}

export default function ShippingSteps({ steps }: Props) {
  const getCurrentStep = () => {
    let currentStep = 0;
    steps.forEach((step: any, index: number) => {
      if (step?.states?.length > 0) {
        currentStep = index;
      }
    });
    return currentStep + 1;
  };
  const currentStep = getCurrentStep();
  return (
    <div className={styles.container}>
      {steps.map((step: any, index: number) => {
        const stepIndex = index + 1;
        return (
          <div key={stepIndex} className={styles.stepContainer}>
            <div className={styles.stepNumber} style={{ backgroundColor: currentStep >= stepIndex ? "#8dc63f" : "" }}>
              {currentStep >= stepIndex && <CheckIcon />}
              {currentStep < stepIndex && <>{stepIndex}</>}
            </div>
            <div className={styles.stepContent}>
              <ShippingStep stepIndex={stepIndex} step={step} currentStep={currentStep} />
            </div>
          </div>
        );
      })}
    </div>
  );
}
