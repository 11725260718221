import * as React from "react";
import styles from "./WelcomeCard.module.css";

interface Props {
  deliveryMode: string;
  order: Order;
}

export default function WelcomeCard({ deliveryMode, order }: Props) {
  const receiver = order?.receiver?.name;
  const description: any = {
    Normal:
      "Somos iFLOW eCommerce y nos estaremos encargando de la entrega de tu pedido en el domicilio indicado.",
    Inversa:
      "Somos iFLOW eCommerce, nos estaremos encargando de retirar tu pedido en el domicilio indicado y coordinaremos su devolución al vendedor. Recordá tener tu paquete embalado y etiquetado para que podamos retirarlo.",
  };
  return (
    <div className={styles.container}>
      <div className={styles.postalWorkerContainer}>
        <img
          className={styles.postalWorker}
          src="/Mail_sent-bro.svg"
          alt="Postal worker"
        />
      </div>
      <div>
        <div className={styles.title}>Hola, {receiver}!</div>
        <div className={styles.description}>{description[deliveryMode]}</div>
      </div>
    </div>
  );
}
