import axios from "axios";

export const apiBaseURL = () => {
  const hostname = window.location.hostname;
  if (hostname === "localhost") {
    // return "http://api.tracking.smdesarrollos.com.ar";
    return "http://localhost:3000";
  } else if (hostname === "tracking.iflow21.com") {
    return `${window.location.protocol}//api-btp.iflow21.com`;
  } else {
    return `${window.location.protocol}//api-${window.location.host}`;
  }
};

const instance = axios.create({
  baseURL: apiBaseURL(),
});

export const orderSearch = async (id: string): Promise<SearchRespose> => {
  try {
    const response = await instance.get(`/tracking/order/search/${id}`);
    return response?.data;
  } catch (e) {
    throw e;
  }
};

export const getInfoByTrackingId = async (
  trackingId: string
): Promise<Order> => {
  try {
    const response = await instance.get(`/tracking/order/${trackingId}`);
    return response?.data;
  } catch (e) {
    throw e;
  }
};

export const sendNps = async (
  trackingId: string,
  score: number,
  description: String
): Promise<Order> => {
  const body = {
    trackingId,
    score,
    description,
  };
  const response = await instance.post("/nps", body);
  return response?.data;
};
